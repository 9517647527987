<template>
    <Layout :footer-style="2">
        <!-- Start Slider Area -->
        <div class="demo-slider-area slider-area bg-transparent slider-style-1 pb--100 pt--70">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner text-center">
                            <div class="react-image mb--20" data-aos="fade-up">
                                <img src="../assets/images/demo/badge-vue-js.png" alt="Doob Vue"/>
                            </div>
                            <h1 class="title display-two" data-aos="fade-up" data-aos-delay="100">
                                自己的房子自己卖 <br/>
                                <span class="theme-gradient">业主直接挂卖</span> &
                                <span class="theme-gradient">真房源</span>
                                <br/>
                                服务按需购买
                            </h1>
                            <p class="description" data-aos="fade-up" data-aos-delay="150">
                                业主自助上传房源发布，优质经纪人、银行、律所、保险、交易中心等合作提供带看、房贷、法律、过户等专业服务
                            </p>
                            <div class="button-group" data-aos="fade-up" data-aos-delay="200">
                                <a class="btn-default btn-large round btn-icon" href="#demo">查看更多
                                    <Icon name="arrow-down"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <Separator/>

        <!-- Start Our Demo -->
        <div class="rn-demo-area rn-section-gap" id="demo">
            <div class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="All Demo Here"
                            title="Doob All Demo."
                            description="We create a business and consulting Vue.js template with 50+ elements features. <br /> 19+ demo pages, faster loading and well documentated code."
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-4 col-md-6 col-12 mt--40"
                         v-for="(page, index) in demoPages"
                         :key="index"
                         data-aos="fade-up"
                         :data-aos-delay="100 + index">
                        <div class="single-demo">
                            <router-link :to="page.url">
                                <div class="thumbnail">
                                    <img class="image-dark" :src="page.image" alt="Corporate images"/>
                                    <img class="image-light" :src="page.imageLight" alt="Corporate images"/>
                                    <span v-if="page.badge" class="label-badge">{{ page.badge }}</span>
                                </div>
                            </router-link>
                            <h4 class="title">
                                <router-link :to="page.url">{{ page.title }}</router-link>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Our Demo -->

        <Separator/>

        <!-- Start Inner Pages -->
        <div class="rn-inner-pages rn-section-gap">
            <div class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="All Others Pages"
                            title="All Others Pages."
                            description="Have a nice inner pages include in doob template."
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-12 mt--40"
                         v-for="(page, index) in innerPages"
                         :key="index"
                         data-aos="fade-up"
                         :data-aos-delay="100 + index">
                        <div class="single-demo">
                            <router-link :to="page.url">
                                <div class="thumbnail">
                                    <img class="image-dark" :src="page.image" alt="Corporate images"/>
                                    <img class="image-light" :src="page.imageLight" alt="Corporate images"/>
                                </div>
                            </router-link>
                            <h4 class="title">
                                <router-link :to="page.url">{{ page.title }}</router-link>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Inner Pages -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Icon from '../components/icon/Icon'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'

    export default {
        name: 'Demo',
        components: {Icon, Layout, Separator, SectionTitle},
        data() {
            return {
                demoPages: [
                    {
                        url: '/business-consulting',
                        image: require('@/assets/images/demo/business-consulting.png'),
                        imageLight: require('@/assets/images/demo/business-consulting-light.png'),
                        title: 'Business Consulting',
                        badge: '',
                    },
                    {
                        url: '/business-consulting-2',
                        image: require('@/assets/images/demo/business-consulting-2.png'),
                        imageLight: require('@/assets/images/demo/business-consulting-2-light.png'),
                        title: 'Business Consulting 2',
                        badge: '',
                    },
                    {
                        url: '/corporate',
                        image: require('@/assets/images/demo/corporate.png'),
                        imageLight: require('@/assets/images/demo/corporate-light.png'),
                        title: 'Corporate',
                        badge: '',
                    }
                ],
                innerPages: [
                    {
                        id: '1',
                        url: '/blog-grid',
                        image: require('@/assets/images/demo/blog-grid.png'),
                        imageLight: require('@/assets/images/demo/blog-grid-light.png'),
                        title: 'Blog Grid'
                    },
                    {
                        id: '2',
                        url: '/blog-grid-sidebar',
                        image: require('@/assets/images/demo/blog-grid-sidebar.png'),
                        imageLight: require('@/assets/images/demo/blog-grid-sidebar-light.png'),
                        title: 'Blog Grid Sidebar'
                    },
                    {
                        id: '3',
                        url: '/blog-list-view',
                        image: require('@/assets/images/demo/blog-list-view.png'),
                        imageLight: require('@/assets/images/demo/blog-list-view-light.png'),
                        title: 'Blog List View'
                    }
                ]
            }
        }
    }
</script>